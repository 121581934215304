import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, FormFlow } from '../../Components';
import SignForm from '../../Components/SignForm';
import { FluxoFlow, Formulario } from '../../interfaces/form-flow';
import {
  getAnswerForm,
  getFluxoFlowBySlug,
  getFormBySlugFluxo,
  startRequestFlow,
} from '../../services/form-flow';
import getCookie from '../../utils/getCookies';
import { setCookie } from '../../utils/setCookie';
import RequestModal from './components/RequestModal';
import StepperCard from './components/StepperCard';
import useStyles from './styles';

export default function TempForm(): JSX.Element {
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const [fluxoFlow, setFluxoFlow] = useState<FluxoFlow>();
  const [forms, setForms] = useState<Formulario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startSign, setStartSign] = useState<boolean>(false);
  const [hasFormStarted, setHasFormStart] = useState<boolean>(false);
  const [formStartedMessage, setFormStartedMessage] = useState<string>('');
  const [formsAnswer, setFormsAnswer] = useState<any[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [showModalResult, setShowModalResult] = useState<boolean>(true);
  const [requestMyData, setRequestMyData] = useState<boolean | undefined>(
    undefined,
  );

  const classes = useStyles();

  const linksBreadcrumb = [
    {
      name: `${fluxoFlow?.titulo || ''} `,
      redirectTo: '#',
    },
  ];

  const getAnswers = async () => {
    const user = JSON.parse(getCookie('gov_user_data') || '{}');
    const formStarted = JSON.parse(getCookie('gov_user_form_started') || '{}');
    setLoading(true);
    try {
      if (user.cpf && formStarted.identificador) {
        const { data } = await getAnswerForm({
          identificador: formStarted.identificador,
          cpf: user.cpf,
        });
        setFormsAnswer(data.results);
      } else {
        setHasFormStart(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const startFormFlow = async (slug: string) => {
    const user = JSON.parse(getCookie('gov_user_data') || '{}');
    if (user.cpf) {
      try {
        setLoading(true);
        const { status, data } = await startRequestFlow(slug, user.cpf);
        if (data) {
          setCookie('gov_user_form_started', JSON.stringify(data));
          if (status === 200) {
            await getAnswers();
          }
        }
      } catch (error) {
        setFormStartedMessage(error?.response?.data?.message || '');
        if (error?.response?.status === 406) {
          setHasFormStart(true);
          sessionStorage.removeItem('gov_user_form_started');
          await getAnswers();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleModal = useCallback(
    () => setShowModalResult(!showModalResult),
    [showModalResult],
  );

  const getFluxoFlow = async () => {
    setLoading(true);
    try {
      const { data } = await getFluxoFlowBySlug(slug);
      setFluxoFlow(data.results[0]);

      if (!data.results[0].terceiros) {
        setRequestMyData(true);
        handleModal();
      }
    } finally {
      setLoading(false);
    }
  };

  const getFormsFlow = async () => {
    setLoading(true);
    try {
      const { data } = await getFormBySlugFluxo(slug);
      setForms(data.results);
    } finally {
      setLoading(false);
    }
  };

  const getOrderByForms = () => {
    return forms.sort((a, b) => {
      if (a.ordem < b.ordem) return -1;
      if (a.ordem > b.ordem) return 1;
      return 0;
    });
  };

  const handleStep = (step: number) => setActiveStep(step);

  useEffect(() => {
    getFluxoFlow();
    getFormsFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (fluxoFlow) {
      startFormFlow(fluxoFlow.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fluxoFlow]);

  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <RequestModal
          onClose={handleModal}
          open={showModalResult}
          isForMy={requestMyData}
          setIsForMy={setRequestMyData}
        />
        <Grid container>
          <Grid item xs={12}>
            <Box padding={2}>
              {fluxoFlow && (
                <Typography className={classes.title}>
                  <div dangerouslySetInnerHTML={{ __html: fluxoFlow.titulo }} />
                </Typography>
              )}
              {fluxoFlow && (
                <Typography className={classes.description}>
                  <div
                    dangerouslySetInnerHTML={{ __html: fluxoFlow.descricao }}
                  />
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems='center' justify='center' spacing={2}>
          <Grid item xs={12}>
            <Card className={classes.mainCard}>
              <CardContent className={classes.cardContent}>
                {loading ? (
                  <Box className={classes.boxContent}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box className={classes.boxContent}>
                    <StepperCard
                      itens={forms}
                      activeStep={hasFormStarted ? forms.length : activeStep}
                    />
                    {!startSign ? (
                      <>
                        {!hasFormStarted && requestMyData !== undefined ? (
                          <FormFlow
                            formData={getOrderByForms()}
                            handleStep={step => handleStep(step)}
                            page={activeStep}
                            handleSubmit={(value, error) => {
                              setStartSign(true);
                            }}
                            formsAnswerData={formsAnswer}
                            requestMyData={requestMyData}
                          />
                        ) : (
                          <>
                            <Typography align='center'>
                              {formStartedMessage}{' '}
                            </Typography>
                            <Button
                              className={classes.buttonForm}
                              variant='contained'
                              fullWidth
                              color='primary'
                              type='submit'
                              style={{ marginTop: 20 }}
                              onClick={() =>
                                history.push('/workspace/minhas_solicitacoes')
                              }
                            >
                              Minhas solicitações
                            </Button>
                            <Button
                              style={{ color: '#0F6FB7', width: '100%' }}
                              variant='text'
                              onClick={() => history.push('/')}
                            >
                              Home{' '}
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <SignForm
                        handleStep={step => handleStep(step)}
                        page={activeStep}
                        handleSubmit={(value, error) =>
                          console.log(value, error)
                        }
                        hasTerms={fluxoFlow?.termo ? fluxoFlow.termo : null}
                      />
                    )}
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
