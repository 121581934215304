import {
  Box,
  Dialog,
  Fade,
  IconButton,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { IoMdClose } from 'react-icons/io';
import { MdBusiness, MdBusinessCenter, MdFace } from 'react-icons/md';
import getCookie from '../../utils/getCookies';
import { firstName } from '../../utils/nameFormat';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: '8px',
    padding: '0px 24px 24px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px 16px 16px',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    color: '#212121',
    textTransform: 'capitalize',
  },
  description: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#212121',
  },
  containerCards: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '61px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      gap: '12px',
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '166px',
    height: '80px',
    border: '1px solid #868686',
    gap: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#868686',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '162px',
    },
  },
  later: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.primary.main,
    textAlign: 'center',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  closeIcon: {
    color: '#212121',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold',
  },
}));

interface ModalProfileProps {
  open: boolean;
  onChange: () => void;
  handleChangeProfile: (value: string) => void;
}

const ModalProfile = ({
  open,
  onChange,
  handleChangeProfile,
}: ModalProfileProps) => {
  const classes = useStyles();
  const userData = JSON.parse(getCookie('gov_user_data') || '{}');

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => onChange()}
      maxWidth='sm'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Fade in={open}>
        <div className={classes.container}>
          <Box component='header' className={classes.header}>
            <Box className={classes.title}></Box>
            <IconButton
              className={classes.closeIcon}
              onClick={() => onChange()}
            >
              <IoMdClose />
            </IconButton>
          </Box>
          <h1 className={classes.title}>
            Olá, {firstName(userData?.nome?.toLowerCase())}!
          </h1>
          <p className={classes.description}>
            Este é seu primeiro acesso, por favor, qual perfil você gostaria que
            fosse utilizado como padrão para quando realizar o acesso novamente.
          </p>
          <div className={classes.containerCards}>
            <Box
              className={classes.card}
              onClick={() => {
                handleChangeProfile('Cidadão');
              }}
            >
              <MdFace className={classes.icon} />
              <Typography className={classes.textMenuItem}>Cidadão</Typography>
            </Box>
            <Box
              className={classes.card}
              onClick={() => {
                handleChangeProfile('Servidor');
              }}
            >
              <MdBusinessCenter className={classes.icon} />
              <Typography className={classes.textMenuItem}>Servidor</Typography>
            </Box>
            <Box
              className={classes.card}
              onClick={() => handleChangeProfile('Empresa')}
            >
              <MdBusiness className={classes.icon} />
              <Typography className={classes.textMenuItem}>Empresa</Typography>
            </Box>
          </div>

          <Typography className={classes.later} onClick={() => onChange()}>
            Definir depois
          </Typography>
        </div>
      </Fade>
    </Dialog>
  );
};

export default ModalProfile;
