import { Menu, MenuItem, Typography, useMediaQuery } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core/styles';
import React from 'react';
import { nameSplitOne, nameSplitTwo } from '../../utils/nameFormat';

import { PersonOutline } from '@material-ui/icons';
import CallToActionOutlined from '@material-ui/icons/CallToActionOutlined';
import InsertInvitationOutlined from '@material-ui/icons/InsertInvitationOutlined';
import { HiOutlineLogout } from 'react-icons/hi';
import { MdOutlinePrivacyTip } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { UserData } from '../../interfaces/user';
import { extractDateFormat, extractHourFormat } from '../../utils/dateFormat';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      padding: '3px',
    },
  }),
)(Badge);

interface DotProps {
  color: string;
  width: string;
  height: string;
}

const Dot: React.FC<DotProps> = ({ color, width, height }) => {
  const useStyles = makeStyles({
    dot: {
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontWeight: 'bold',
      backgroundColor: color,
      width: width,
      height: height,
      borderRadius: '100px',
      gap: '10px',
      position: 'absolute',
      fontSize: '10px',
      top: 0,
      right: 0,
    },
  });

  const classes = useStyles();
  return <div className={classes.dot}>99+</div>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    menusList: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#212121',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 700,
      padding: '8px 12px',
      borderTop: '1px solid #DDDDDD',
      gap: '12px',
    },
    avatar: {
      height: 56,
      width: 56,
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        height: 40,
        width: 40,
      },
    },
    avatarMenu: {
      height: 56,
      width: 56,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        height: 40,
        width: 40,
      },
    },
    containerAvatar: {
      display: 'flex',
      padding: '12px 2px 12px 6px',
      gap: '8px',
      alignItems: 'center',
    },
    textName: {
      fontSize: 14,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: '#212121',
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 300,
      color: '#212121',
      textAlign: 'start',
    },
    icon: {
      width: '24px',
      height: '24px',
      color: theme.palette.primary.main,
    },
  }),
);

const listCards = [
  {
    icon: <PersonOutline style={{ fontSize: 24 }} />,
    title: 'Meu perfil',
    description: 'Altera os seus dados básicos.',
    action: 'Acessar perfil',
    link: '/workspace/minha-area/meus-dados',
  },
  {
    icon: <InsertInvitationOutlined style={{ fontSize: 24 }} />,
    title: 'Meus agendamentos',
    description: 'Veja os seus agendamentos.',
    action: 'Acessar agendamentos',
    link: '/workspace/meus_agendamentos',
  },
  {
    icon: <CallToActionOutlined style={{ fontSize: 24 }} />,
    title: 'Minhas ações',
    description: 'Veja todas as ações recentes.',
    action: 'Acessar minhas ações',
    link: '/workspace/atividades',
  },
  {
    icon: <MdOutlinePrivacyTip style={{ fontSize: 24 }} />,
    title: 'Privacidade',
    description: 'Acesses dados de privacidade.',
    action: 'Acessar minhas ações',
    link: '/workspace/minha-area/privacidade',
  },
];

interface BadgeAvatarsProps {
  avatarUrl: string;
  user: UserData;
  logout: () => void;
}

export default function BadgeAvatars({
  avatarUrl,
  user,
  logout,
}: BadgeAvatarsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <div style={{ position: 'relative', cursor: 'pointer' }}>
        <StyledBadge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap='circle'
          variant='dot'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
        >
          <Avatar
            alt='usuário foto'
            src={avatarUrl}
            className={classes.avatar}
          />
          <Dot color='red' width='25px' height='15px' />
        </StyledBadge>
        <div>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <div style={{ width: '206px' }}>
              <div className={classes.containerAvatar}>
                <div style={{ position: 'relative' }}>
                  <StyledBadge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    overlap='circle'
                    variant='dot'
                  >
                    <Avatar
                      alt='usuário foto'
                      src={avatarUrl}
                      className={classes.avatarMenu}
                    />
                    <Dot color='red' width='25px' height='15px' />
                  </StyledBadge>
                </div>
                <div>
                  <Typography className={classes.textName} color='textPrimary'>
                    {isMobile
                      ? nameSplitTwo(user.nome?.toLowerCase())
                      : nameSplitOne(user.nome?.toLowerCase())}
                  </Typography>
                  <Typography
                    className={`${classes.textCpf} py-4`}
                    color='textPrimary'
                  >
                    {user?.contato?.email || 'Email não informado!'}
                  </Typography>
                  <Typography
                    color='textPrimary'
                    style={{ fontSize: '8px' }}
                    className={classes.textCpf}
                  >
                    Último acesso:{' '}
                    {`${extractDateFormat(
                      user.last_login,
                    )} às ${extractHourFormat(user.last_login)}`}
                  </Typography>
                </div>
              </div>
              {listCards.map(listaMenus => {
                return (
                  <>
                    <Link
                      to={listaMenus.link}
                      style={{ textDecoration: 'none' }}
                    >
                      <MenuItem className={classes.menusList}>
                        {' '}
                        <div className={classes.icon}>{listaMenus.icon}</div>
                        {listaMenus.title}
                      </MenuItem>
                    </Link>
                  </>
                );
              })}
              <MenuItem className={classes.menusList} onClick={() => logout()}>
                <div className={classes.icon}>
                  <HiOutlineLogout style={{ fontSize: 24 }} />
                </div>{' '}
                Sair
              </MenuItem>
            </div>
          </Menu>
        </div>
      </div>
    </div>
  );
}
