import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Paper,
  Slider,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { MdAddAPhoto, MdArrowBack, MdCheckCircleOutline } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from '../../Components';
import { UserData } from '../../interfaces/user';
import { getImageProfile, sendImageProfile } from '../../services/auth';
import { cpfMask } from '../../utils/cpfFormat';
import getCookie from '../../utils/getCookies';
import { getCroppedImg } from './cropImage';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  alertCustom: {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderLeft: '5px solid #ffa726',
    borderRadius: 10,
    color: '#545758',
    '& a': {
      color: '#1976d2',
    },
  },
  boxIndicatorsBack: {
    width: '100%',

    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  cardData: {
    maxWidth: 680,
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingLeft: 50,
    paddingRight: 50,
  },
  boxAvatar: {
    marginTop: 20,
    border: `2px solid ${theme.palette.primary.main}`,
    width: 80,
    height: 80,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    height: 64,
    width: 64,
    backgroundColor: '#E1EDF6',
  },
  userName: {
    fontSize: 16,
    color: '#373F41',
    fontWeight: 'normal',
    paddingTop: 16,
    fontFamily: 'Roboto',
  },
  userCpf: {
    fontSize: 16,
    color: '#373F41',
    fontWeight: 'normal',
    padding: '4px 0px 24px',
    opacity: 0.5,
  },
  input: {
    display: 'none',
  },
  button: {
    minWidth: 161,
    height: 44,
    fontSize: '100%',
    borderRadius: 4,
    padding: '12px 16px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  labelButton: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  inputForm: {
    margin: '24px 0px',
    color: '#DDD',
  },
  buttonReturnAndSave: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 16,
  },
  buttonReturn: {
    width: 83,
    marginRight: 16,
    borderRadius: 4,
    color: '#373F41',
    border: 0,
    textTransform: 'uppercase',
  },
  buttonSave: {
    width: 83,
  },
  icon: {
    width: 24,
    height: 24,
  },
}));

export default function MyData(): JSX.Element {
  const classes = useStyles();
  const [userData, setUserDada] = useState<UserData | undefined>();
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [crop, setCrop] = useState({ x: 100, y: 100 });
  const [savedImage, setSavedImage] = useState<boolean>(false);
  const [zoom, setZoom] = useState(1);
  const [blob, setBlob] = useState(null);
  const [avatarUser, setAvatarUser] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const userDataStorage: string | null = getCookie('gov_user_data');
  const token: string | null = getCookie('gov_access_token_sso');
  const history = useHistory();

  const handleChangeImage = e => {
    setOpenModal(true);
    const reader = new FileReader();
    if (e.target.files.length) {
      const fileArquive = e.target.files[0];
      reader.addEventListener(
        'load',
        () => {
          setImage({
            preview: URL.createObjectURL(fileArquive),
            raw: reader.result as string,
          });
        },
        false,
      );
      e.target.value = '';
      if (fileArquive) {
        reader.readAsDataURL(fileArquive);
      }
    }
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage: Blob = await getCroppedImg(
      image.raw,
      croppedAreaPixels,
    );
    setBlob(URL.createObjectURL(croppedImage));
    const file = new File([croppedImage], `avatar-${Date.now()}.png`, {
      lastModified: new Date().getTime(),
      type: 'image/png',
    });

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      setFile(file);
    };
  };

  const handleSave = async () => {
    setLoading(true);
    if (file) {
      const response = await sendImageProfile(token, file);
      if (response) {
        setSavedImage(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userDataStorage) {
      setUserDada(JSON.parse(userDataStorage));
    }
  }, [userDataStorage]);

  useEffect(() => {
    const handleGetImageProfile = async () => {
      const { data } = await getImageProfile(token);

      if (data?.results?.length > 0) {
        const image = data.results[0].arquivo;
        setAvatarUser(image);
      }
    };
    handleGetImageProfile();
  }, [token]);

  const linksBreadcrumb = [
    {
      name: 'Dados básicos',
      redirectTo: '#',
    },
  ];

  const getLatestUserLevel = (users): string => {
    if (users.length === 0) {
      return 'Não informado';
    }

    const latestUser = users.reduce(
      (latest, user) =>
        new Date(user.dataAtualizacao) > new Date(latest.dataAtualizacao)
          ? user
          : latest,
      users[0],
    );

    switch (latestUser.id) {
      case '1':
        return 'Bronze';
      case '2':
        return 'Prata';
      case '3':
        return 'Ouro';
      default:
        return 'Não informado';
    }
  };

  return (
    <>
      <Dialog open={savedImage} onClose={() => history.go(0)}>
        <DialogContent>
          <MdCheckCircleOutline
            style={{
              width: 60,
              height: 60,
              color: '#4bbd7e',
              textAlign: 'center',
            }}
          />
          <Typography
            style={{ color: '#333', fontFamily: 'Roboto', fontSize: 22 }}
          >
            Imagem de perfil salva com sucesso!!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.buttonSave}
            onClick={() => history.go(0)}
            variant='contained'
            color='primary'
            size='small'
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      {image.preview && (
        <Dialog open={openModal}>
          <DialogContent>
            <Box width={500} height={474}>
              <Cropper
                image={image.preview}
                crop={crop}
                zoom={zoom}
                cropSize={{ width: 300, height: 300 }}
                cropShape='round'
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                maxZoom={5}
              />
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              zIndex: 2,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box width='100%' padding='30px 50px'>
              <Slider
                step={1}
                max={5}
                min={1}
                value={zoom}
                onChange={(e, newValue: any) => setZoom(newValue)}
                aria-labelledby='continuous-slider'
              />
            </Box>
            <Box display='flex' alignSelf='flex-end'>
              <Button
                onClick={() => {
                  setOpenModal(false);
                  setImage({
                    preview: '',
                    raw: '',
                  });
                  setZoom(1);
                  setBlob(null);
                }}
                className={classes.buttonReturn}
                variant='outlined'
              >
                Cancelar
              </Button>
              <Button
                autoFocus
                onClick={() => setOpenModal(false)}
                className={classes.buttonSave}
                variant='contained'
                color='primary'
                size='small'
              >
                Salvar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push('/workspace')}>
                  <MdArrowBack className={classes.icon} />
                </IconButton>
                <Typography style={{ fontFamily: 'Roboto', fontWeight: 700 }}>
                  Dados básicos
                </Typography>
                <Paper square>
                  <Tabs
                    // value={selectTab}
                    indicatorColor='primary'
                    textColor='primary'
                    // variant='fullWidth'
                    aria-label='full width tabs example'
                    // onChange={handleChange}
                    // className={classes.tabsInfo}
                  ></Tabs>
                </Paper>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Card className={classes.cardData}>
              <CardContent>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Box className={classes.boxAvatar}>
                    <Avatar
                      src={blob || avatarUser}
                      className={classes.avatar}
                    />
                  </Box>
                  {userData && userData?.nome}
                  {userData && userData?.cpf}
                  <Typography className={classes.userName}>
                    {userData?.nome || 'Não informado'}
                  </Typography>
                  <Typography className={classes.userCpf}>
                    {cpfMask(userData?.cpf) || 'Não informado'}
                  </Typography>
                  <div style={{ paddingBottom: '16px' }}>
                    <input
                      accept='image/*'
                      className={classes.input}
                      id='contained-button-file'
                      onChange={handleChangeImage}
                      type='file'
                    />
                    <label
                      htmlFor='contained-button-file'
                      className={classes.labelButton}
                    >
                      <Button
                        variant='outlined'
                        color='primary'
                        component='span'
                        startIcon={
                          <MdAddAPhoto style={{ width: 20, height: 20 }} />
                        }
                        className={classes.button}
                      >
                        ALTERAR FOTO
                      </Button>
                    </label>
                  </div>
                </Box>
                <div className={classes.inputForm}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-email'>
                      Email
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-email'
                      type='text'
                      label='Email'
                      autoFocus
                      value={userData?.contato?.email || 'Não informado'}
                      disabled
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                <div className={classes.inputForm}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-contact-cell'>
                      Contato de celular
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-adornment-gov-nivel'
                      type='text'
                      label='Contato de Celular'
                      disabled
                      value={userData?.contato?.celular || 'Não informado'}
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                <div className={classes.inputForm}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-contact-cell'>
                      Nível da conta Gov.Br:
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-adornment-contact-cell'
                      type='text'
                      label='Nível da conta Gov.Br:'
                      disabled
                      value={
                        getLatestUserLevel(userData?.govbr_niveis || []) ||
                        'Não informado'
                      }
                      labelWidth={150}
                    />
                  </FormControl>
                </div>
                <Alert
                  severity='warning'
                  className={`shadow b ${classes.alertCustom}`}
                >
                  Para alterar suas informações de e-mail e celular, você
                  precisa realizar a alteração diretamente no Gov.Br acessando o
                  endereço
                  <a
                    href='https://contas.acesso.gov.br/alteracao_cadastro'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    https://contas.acesso.gov.br/alteracao_cadastro
                  </a>
                  . Após a alteração faça um novo login em nosso portal para
                  recebermos a atualização.
                </Alert>
                {/* <div className={classes.inputForm}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-add-address'>
                      Adicionar endereço
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-add-address'
                      type='text'
                      label='Adicionar endereço'
                      disabled
                      value={userData?.endereco || 'Não informado'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <Edit color='action' />
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div> */}
                <div className={classes.buttonReturnAndSave}>
                  <Button
                    variant='outlined'
                    className={classes.buttonReturn}
                    onClick={() => history.go(-1)}
                  >
                    VOLTAR
                  </Button>
                  {loading ? (
                    <CircularProgress color='primary' />
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      className={classes.buttonSave}
                      onClick={handleSave}
                    >
                      SALVAR
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
