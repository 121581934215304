import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Star } from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/styles';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { MdArrowBack, MdAttachFile, MdExpandLess, MdExpandMore } from "react-icons/md";
import { useHistory } from 'react-router';
import { Breadcrumb } from '../../Components';
import { RespostasI, RespostasII } from '../../interfaces/form-flow';
import { getRequests } from '../../services/form-flow';
import { extractDateFormat, extractHourFormat } from '../../utils/dateFormat';
import getCookie from '../../utils/getCookies';
import { ModalResponse, PaginationRequest, RequestModalCancel } from './components';
import ButtonActions from './components/ButtonActions';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    margin: "20px 0px",
  },
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
    display: 'flex',
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: 225,
    [theme.breakpoints.down("xs")]: {
      height: 294,
    },
  },
  cardContent: {
    padding: 30,
    display: "flex",
    width: '100%',
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: "20px 10px 0px 10px",
    },
  },
  iconAccess: {
    color: theme.palette.primary.main,
    marginBottom: 20,
    marginTop: 8,
    fontSize: 50,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
    marginRight: 147.5,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 16,
      marginRight: 0,
      textAlign: "center",
    },
  },
  boxButton: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  button: {
    height: 44,
  },
  boxIndicatorsBackHome: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBackHome: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  status: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textTransform: 'capitalize',
    padding: '5px 16px',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      marginBottom: 24,
      alignSelf: 'center',
    },
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    margin: '24px 0px'
  },
  textTable: {
    color: '#333333',
  },
  attachText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textDecoration: 'none',
    color: '#0F6FB7',
  },
  textLink: {
    margin: '5px 0px 5px 14px',
    textDecoration: 'none',
    color: '#0F6FB7'
  },
  buttonCancel: {
    color: '#E53535',
    borderColor: 'rgba(229,53,53, 0.7)',
    borderRadius: 4,
    margin: '0px 7px',
    padding: '12px 24px',
    height: 48,
    '&:hover': {
      backgroundColor: 'rgba(229,53,53, 0.1)',
      borderColor: '#E53535',
    },
  },
  actionsButtonText: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
  },
  textButtonGuiche: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    margin: '0px 7px',
    width: 131,
    height: 48,
    borderRadius: 4,
    padding: '12px 24px',
  },
  boxActions: {
    margin: '30px 0px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  boxInfoCancel: {
    width: '100%',
  },
  infoCancelText: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
  },
  iconRequests: {
    height: 24,
    width: 24,
  },
  containerEvaluation: {
    display: 'flex',
    gap: 10,
  },
  boxStar: {
    display: 'flex',
    gap: 10,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    alignItems: 'center',
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20)
  },
  textLabelEvaluation: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left'
  },
  starCountText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left',
    color: '#373F41',
  }

}));

export interface RestitutionSelected {
  solicitacao: number;
  id: string;
}

export interface HistoryData {
  id: number;
  descricao: string;
  status: string;
  user: any;
  hash: null | string;
  created_at: string;
  updated_at: string;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(15, 111, 183, 0.05)",
      color: theme?.palette?.primary?.main,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    body: {
      fontSize: 14,
      color: '#333333'
    },
  })
)(TableCell);


export default function MyRequests(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [listRequests, setListRequests] = useState<RespostasII>();
  const [showDetails, setShowDetails] = useState<string>('');
  const [currentRequestId, setCurrentRequestId] = useState<number | null>(null);
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [restitutionSelected, setRestitutionSelected] = useState<RestitutionSelected | null>();
  const [descriptionRestitution, setDescriptionRestitution] = useState<string | null>();
  const [showModalResponse, setShowModalResponse] = useState<boolean>(false);


  const getRequestsList = async ({ page }: { page?: number }) => {
    setLoading(true);
    try {
      const user = JSON.parse(getCookie('gov_user_data') || '{}');
      if (user?.cpf) {
        const { data } = await getRequests({ page, cpf: user.cpf });
        setListRequests(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleModal = useCallback(() =>
    setShowModalCancel((oldModal) => !oldModal), []);

  const handleOpenModalResponse = useCallback(() => {
    setShowModalResponse((oldShowModal) => !oldShowModal);
  }, []);

  const handleGetDescriptionRestitution = useCallback((historic: HistoryData[]) => {
    const descriptionRestitution = historic.find((item) => item.status === 'Restituído')?.descricao;
    setDescriptionRestitution(descriptionRestitution);
  }, []);

  const contentEvaluation = useCallback((avaliacao_flow: RespostasI) => {
    return (
      <Fragment>
        {avaliacao_flow?.avaliacao_solicitacao ? (
          <>
            {!avaliacao_flow?.avaliacao_solicitacao?.pendente ? (
              <Box className={classes.containerEvaluation}>
                <Box>
                  <Typography className={classes.titleInfo}>
                    Avaliação do Serviço
                  </Typography>
                  <span className={classes.boxStar}>
                    <Star className={classes.starIcon} />
                    <Typography className={classes.starCountText}>
                      {avaliacao_flow?.avaliacao_solicitacao?.avaliacao_informacao} estrelas
                    </Typography>
                  </span>
                </Box>
                <Box>
                  <Typography className={classes.titleInfo}>
                    Avaliação do Atendimento
                  </Typography>
                  <span className={classes.boxStar}>
                    <Star className={classes.starIcon} />
                    <Typography className={classes.starCountText}>
                      {avaliacao_flow?.avaliacao_solicitacao?.avaliacao_atendimento} estrelas
                    </Typography>
                  </span>
                </Box>
              </Box>
            ) : (
              <Typography className={classes.titleInfo}>
                Avaliação pendente
              </Typography>
            )}
          </>
        ) : (
          <Typography className={classes.titleInfo}>
            Avaliação indisponível
          </Typography>
        )}
      </Fragment>
    )
  }, [classes]);

  const orderHistoric = (a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  }

  useEffect(() => {
    getRequestsList({ page: 1 })
  }, []);

  const linksBreadcrumb = [
    {
      name: 'Minhas solicitações',
      redirectTo: '#',
    }
  ];
  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Box>
          <Box className={classes.boxIndicatorsBack}>
            <Box className={classes.indicatorsBack}>
              <IconButton
                onClick={() =>
                  history.push("/workspace")
                }
              >
                <MdArrowBack />
              </IconButton>
              <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                Minhas Solicitações
              </Typography>
            </Box>
          </Box>
        </Box>
        <Card>
          <CardContent className={classes.cardContent}>
            {/* <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel htmlFor="outlined-adornment-search-service">
                Buscar um serviço
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search-service"
                type="text"
                label="Buscar um serviço"
                style={{ color: "#373F41" }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <MdSearch className={classes.iconRequests} color="primary" />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl> */}
          </CardContent>
          {loading ? (
            <Box
              marginBottom="60px"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {listRequests?.results.length && !loading ? (
                <>
                  <TableContainer component={Paper} style={{ padding: '0px 30px' }}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center" />
                          <StyledTableCell align="center">situação</StyledTableCell>
                          <StyledTableCell align="center">
                            Serviço
                          </StyledTableCell>
                          <StyledTableCell align="center">Identificador</StyledTableCell>
                          <StyledTableCell align="center">protocolo</StyledTableCell>
                          <StyledTableCell align="center">Atualizado dia</StyledTableCell>
                          <StyledTableCell align="center">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listRequests.results.map((request) => (
                          <Fragment key={request.identificador}>
                            <TableRow>
                              <StyledTableCell>
                                <IconButton aria-label="expand row" size="small" onClick={() => {
                                  showDetails === request.identificador ? setShowDetails('') : setShowDetails(request.identificador);
                                }}>
                                  {showDetails === request.identificador ? <MdExpandLess /> : <MdExpandMore />}
                                </IconButton>
                              </StyledTableCell>
                              {request.status === 'Solicitado' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#373F41',
                                      border: '1px solid rgba(55, 63, 65, 0.05)',
                                      background: 'rgba(55, 63, 65, 0.05)',
                                    }}
                                  >
                                    Solicitado
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Aguardando' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#373F41',
                                      border: '1px solid rgba(55, 63, 65, 0.05)',
                                      background: 'rgba(55, 63, 65, 0.05)',
                                    }}
                                  >
                                    Solicitado
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Finalizado' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#43A047',
                                      border: '1px solid rgba(67, 160, 71, 0.08)',
                                      background: 'rgba(67, 160, 71, 0.08)',
                                    }}
                                  >
                                    Finalizado
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Cancelado pelo usuário' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#F83245',
                                      border: '1px solid rgba(248, 50, 69, 0.08)',
                                      background: 'rgba(248, 50, 69, 0.08)',
                                    }}
                                  >
                                    Cancelado
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Cancelado pelo atendente' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#F83245',
                                      border: '1px solid rgba(248, 50, 69, 0.08)',
                                      background: 'rgba(248, 50, 69, 0.08)',
                                    }}
                                  >
                                    Cancelado
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Em Andamento' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#0F6FB7',
                                      border: '1px solid  rgba(15, 111, 183, 0.05)',
                                      background: ' rgba(15, 111, 183, 0.05)',
                                    }}
                                  >
                                    Em Andamento
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Complementação Cidadão' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: "#0F6FB7",
                                      border:
                                        "1px solid #0F6FB7",
                                      background:
                                        " rgba(5, 51, 150, 0.05)",
                                    }}
                                  >
                                    Complementação Cidadão
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Restituído' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: "#0F6FB7",
                                      border:
                                        "1px solid #0F6FB7",
                                      background:
                                        " rgba(5, 51, 150, 0.05)",
                                    }}
                                  >
                                    Restituído
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Encaminhado' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: "#053396",
                                      border:
                                        "1px solid rgba(5, 51, 150, 0.05)",
                                      background:
                                        " rgba(5, 51, 150, 0.05)",
                                    }}
                                  >
                                    Encaminhado
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === 'Prorrogado' && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#FAAD13',
                                      border: '1px solid rgba(250, 173, 19, 0.10)',
                                      background: 'rgba(250, 173, 19, 0.10)',
                                    }}
                                  >
                                    Prorrogado
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === "Concluído" && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: "#3CD278",
                                      border:
                                        "1px solid rgba(60, 210, 120, 0.10)",
                                      background: "rgba(60, 210, 120, 0.10)",
                                    }}
                                  >
                                    Concluído
                                  </Typography>
                                </StyledTableCell>
                              )}
                              {request.status === "Em Aberto" && (
                                <StyledTableCell align="center">
                                  <Typography
                                    className={classes.status}
                                    style={{
                                      color: '#0F6FB7',
                                      border: '1px solid  rgba(15, 111, 183, 0.05)',
                                      background: ' rgba(15, 111, 183, 0.05)',
                                    }}
                                  >
                                    Em Aberto
                                  </Typography>
                                </StyledTableCell>
                              )}
                              <StyledTableCell align="center">{request.flow.titulo}</StyledTableCell>
                              <StyledTableCell align="center">{request.identificador}</StyledTableCell>
                              <StyledTableCell align="center">{request.protocolo}</StyledTableCell>
                              <StyledTableCell align="center">{`${extractDateFormat(request.updated_at)} às ${extractHourFormat(request.updated_at)}`}</StyledTableCell>
                              <StyledTableCell align="center">
                                <ButtonActions
                                  handleOpenModal={() => {
                                    handleOpenModalResponse();
                                    handleGetDescriptionRestitution(request.historico_flow_resposta);
                                  }}
                                  currentStatus={request.status}
                                  request={request}
                                  onOpenModalCancel={handleModal}
                                  setRequestId={setCurrentRequestId}
                                  restitutionData={request?.restituicao}
                                  setDataRestitution={setRestitutionSelected}
                                  evaluationData={request.avaliacao_solicitacao}
                                  onLoadRequests={getRequestsList}
                                />
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={request.identificador === showDetails} timeout="auto" unmountOnExit>
                                  <Box>
                                    <Typography className={classes.titleInfo}>
                                      Informações
                                    </Typography>
                                    <Table size="small" aria-label="purchases" style={{ marginBottom: 30 }}>
                                      <TableHead>
                                        <TableRow style={{ textTransform: 'uppercase' }}>
                                          <TableCell className={classes.textTable}>Protocolo</TableCell>
                                          <TableCell className={classes.textTable}>serviço</TableCell>
                                          {/* <TableCell className={classes.textTable}>Órgão</TableCell> */}
                                          {/* <TableCell className={classes.textTable}>Solicitante</TableCell> */}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow style={{ textTransform: 'uppercase' }}>
                                          <TableCell component="th" scope="row" className={classes.textTable}>
                                            {request.protocolo}
                                          </TableCell>
                                          <TableCell className={classes.textTable}>{request.flow?.titulo}</TableCell>
                                          {/* <TableCell className={classes.textTable}>Perícia oficial e identificação técnica</TableCell> */}
                                          {/* <TableCell className={classes.textTable}>
                                            Felipe Gabriel Brasil Haussler
                                          </TableCell> */}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                    <Box style={{ margin: '20px 0px' }}>
                                      <Typography className={classes.titleInfo}>
                                        Documentos
                                      </Typography>
                                      {request?.documento_resposta?.map((document, index) => (
                                        <Typography className={classes.attachText} key={index.toString()}>
                                          <MdAttachFile className={classes.iconRequests} />
                                          <Link href={`/workspace/minhas_solicitacoes/view_doc/${document.id}/${request.identificador}`} target='_blanck' className={classes.textLink}>{document.assunto}</Link></Typography>))}
                                      {request?.anexo_documento_resposta?.map((document, index) => (
                                        <Typography className={classes.attachText} key={index.toString()}>
                                          <MdAttachFile className={classes.iconRequests} />
                                          <Link href={document.arquivo} target='-self' className={classes.textLink}>{document.assunto}</Link></Typography>))}

                                      {request?.documento_resposta?.length === 0 && (<Typography className={classes.attachText}>Sem documentos</Typography>)}

                                    </Box>
                                    {/* <Box
                                className={classes.boxInfoCancel}
                              >
                                <Typography className={classes.infoCancelText}>
                                  <Info style={{ color: '#F83245', marginRight: 6 }} /> Cancelado pelo solicitante.
                                </Typography>
                              </Box> */}
                                    <Box>
                                      <Typography className={classes.titleInfo}>
                                        Histórico
                                      </Typography>
                                      <Table size="small" aria-label="purchases" style={{ marginBottom: 30 }}>
                                        <TableHead>
                                          <TableRow style={{ textTransform: 'uppercase' }}>
                                            <TableCell className={classes.textTable}>ordem</TableCell>
                                            <TableCell className={classes.textTable}>status</TableCell>
                                            <TableCell className={classes.textTable}>enviado</TableCell>
                                            <TableCell className={classes.textTable}>descrição</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {request?.historico_flow_resposta?.sort(orderHistoric)
                                            .map((history, index) => (<TableRow key={history.id} style={{ textTransform: 'uppercase' }}>
                                              <TableCell component="th" scope="row" className={classes.textTable} key={index.toString()}>
                                                {index + 1}
                                              </TableCell>
                                              {history?.status === 'Complementação Cidadão' && (
                                                <StyledTableCell align="center">
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: "#0F6FB7",
                                                      border:
                                                        "1px solid #0F6FB7",
                                                      background:
                                                        " rgba(5, 51, 150, 0.05)",
                                                    }}
                                                  >
                                                    Complementação Cidadão
                                                  </Typography>
                                                </StyledTableCell>
                                              )}
                                              {history?.status ===
                                                "Aguardando" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#FAAD13",
                                                        border:
                                                          "1px solid rgba(250, 173, 19, 0.05)",
                                                        background:
                                                          "rgba(250, 173, 19, 0.05)",
                                                      }}
                                                    >
                                                      Aguardando
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Em Andamento" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#FAAD13",
                                                        border:
                                                          "1px solid rgba(250, 173, 19, 0.05)",
                                                        background:
                                                          "rgba(250, 173, 19, 0.05)",
                                                      }}
                                                    >
                                                      Em Andamento
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Prorrogado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#FAAD13",
                                                        border:
                                                          "1px solid rgba(250, 173, 19, 0.10)",
                                                        background:
                                                          "rgba(250, 173, 19, 0.10)",
                                                      }}
                                                    >
                                                      Prorrogado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Arquivado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#F03D3D",
                                                        border:
                                                          "1px solid rgba(240, 61, 61, 0.08)",
                                                        background:
                                                          "rgba(240, 61, 61, 0.08)",
                                                      }}
                                                    >
                                                      Arquivado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Encaminhado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Encaminhado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Em Aberto" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Em Aberto
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Solicitado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Solicitado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Recebido" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Recebido
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Concluído" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#3CD278",
                                                        border:
                                                          "1px solid rgba(60, 210, 120, 0.10)",
                                                        background:
                                                          "rgba(60, 210, 120, 0.10)",
                                                      }}
                                                    >
                                                      Concluído
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Respondido" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#3CD278",
                                                        border:
                                                          "1px solid rgba(60, 210, 120, 0.05)",
                                                        background:
                                                          "rgba(60, 210, 120, 0.05)",
                                                      }}
                                                    >
                                                      Respondido
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Respondido(Reaberto)" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#3CD278",
                                                        border:
                                                          "1px solid rgba(60, 210, 120, 0.05)",
                                                        background:
                                                          "rgba(60, 210, 120, 0.05)",
                                                      }}
                                                    >
                                                      Respondido(Reaberto)
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}

                                              {history?.status === 'Finalizado' && (
                                                <StyledTableCell align="center">
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: "#3CD278",
                                                      border:
                                                        "1px solid rgba(60, 210, 120, 0.05)",
                                                      background:
                                                        "rgba(60, 210, 120, 0.05)",
                                                    }}
                                                  >
                                                    Finalizado
                                                  </Typography>
                                                </StyledTableCell>
                                              )}
                                              {history?.status ===
                                                "Cancelado pelo usuário" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#F03D3D",
                                                        border:
                                                          "1px solid rgba(240, 61, 61, 0.08)",
                                                        background:
                                                          "rgba(240, 61, 61, 0.08)",
                                                      }}
                                                    >
                                                      Cancelado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Cancelado pelo atendente" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#F03D3D",
                                                        border:
                                                          "1px solid rgba(240, 61, 61, 0.08)",
                                                        background:
                                                          "rgba(240, 61, 61, 0.08)",
                                                      }}
                                                    >
                                                      Cancelado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Cancelado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#F03D3D",
                                                        border:
                                                          "1px solid rgba(240, 61, 61, 0.08)",
                                                        background:
                                                          "rgba(240, 61, 61, 0.08)",
                                                      }}
                                                    >
                                                      Cancelado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}

                                              {history?.status ===
                                                "Restituído" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Restituído
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              <TableCell className={classes.textTable}>
                                                {`${extractDateFormat(history?.created_at)} às ${extractHourFormat(history?.created_at)}`}
                                              </TableCell>
                                              <TableCell className={classes.textTable}>
                                                <div dangerouslySetInnerHTML={{ __html: history?.descricao || "Sem informação" }} />
                                              </TableCell>
                                            </TableRow>))}

                                          {request?.historico_flow_resposta?.length === 0 && (<TableCell component="th" scope="row" colSpan={4} className={classes.textTable}>
                                            {'Sem histórico'}
                                          </TableCell>)}

                                        </TableBody>
                                      </Table>
                                    </Box>
                                    {/* <Box className={classes.boxActions}>
                                      <Button
                                        variant="outlined"
                                        color="inherit"
                                        className={classes.buttonCancel}
                                        onClick={() => { }}
                                      >
                                        <Typography className={classes.actionsButtonText}>
                                          Cancelar
                                        </Typography>
                                      </Button>
                                      <Button
                                        className={classes.textButtonGuiche}
                                        color='primary'
                                        variant='outlined'
                                        onClick={() => { }}
                                      >
                                        <Typography className={classes.actionsButtonText}>
                                          Imprimir
                                        </Typography>
                                      </Button>
                                    </Box> */}
                                    {contentEvaluation(request)}
                                  </Box>
                                </Collapse>
                              </StyledTableCell>
                            </TableRow>
                          </Fragment>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                  <PaginationRequest
                    style={{ padding: 0, marginBottom: 0, margin: '20px 0px' }}
                    getSearch={getRequestsList}
                    listSearch={listRequests}
                  />
                </>
              ) : (
                <Box
                  marginBottom="60px"
                  minHeight="100px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <Typography style={{ textAlign: "center" }} variant="h4">
                    Nenhum resultado foi encontrado.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Card>
      </Container>
      {showModalResponse && restitutionSelected && descriptionRestitution && (
        <ModalResponse
          handleClose={handleOpenModalResponse}
          open={showModalResponse}
          restitutionData={restitutionSelected}
          legend={descriptionRestitution}
          onReload={getRequestsList}
        />
      )}

      {showModalCancel && currentRequestId && (
        <RequestModalCancel
          handleClose={handleModal}
          open={showModalCancel}
          requestId={currentRequestId}
          onReload={getRequestsList}
        />
      )}
    </>
  );
}
